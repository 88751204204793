import {
  TeamOutlined,
  NotificationOutlined,
  ContactsOutlined,
  QuestionCircleOutlined,
  FormOutlined
} from '@ant-design/icons'
import { Layout, Menu, MenuProps } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Strings from '../../../common/services/localization-service'
import { USERS_ROUTE } from '../../../users/routes'
import { FAQ_ROUTE } from '../../../faqs/routes'
import './side-menu.scss'
import {
  CONTACT_US_FORM_SUBMISSION_ROUTE,
  CONTACT_US_FORMS_ROUTE,
  CONTACT_US_MESSAGES_ROUTE,
  CONTACT_US_SPAM_MESSAGES_ROUTE
} from '../../../contact-us/routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux-store'
import {
  ENTITIES_ROUTE,
  ENTITY_MESSAGES_ROUTE,
  ENTITY_SUBMISSION_ROUTE
} from '../../../entities/routes'
import { ADMIN, AUTH } from '../../../users/utils/roles-constants'
import { validateSideMenus } from '../../../users/utils/roles-utils'
import { VERSION_ROUTE } from '../../../versions/routes'
import { NOTIFY_USERS_ROUTE } from '../../../notify-users/routes'
import { setLoader } from '../../../common/slices/loader-slice'
import { IContactUsFormResponse } from '../../../contact-us/types/IContactUsFormResponse'
import { getContactUsForms } from '../../../contact-us/services/contact-us-service'
import { showToastMessage } from '../../../common/components/common/toast-message/toast-message'
import { MESSAGE_TYPE_ERROR } from '../../../common/common-constants'
import { getErrorMessage } from '../../../common/utils/common-utils'
const { Sider } = Layout

interface ISideMenu {
  key: string
  icon?: ReactElement
  roles?: string[]
  label: string
  children?: ISideMenu[]
  path?: string
  state?: string
}

const items: ISideMenu[] = [
  {
    key: 'dashboard',
    icon: React.createElement(NotificationOutlined),
    roles: [AUTH],
    label: Strings.dashboard,
    path: '/'
  },
  {
    key: 'users',
    roles: [ADMIN],
    icon: React.createElement(TeamOutlined),
    label: Strings.users,
    path: USERS_ROUTE
  },
  // {
  //   key: 'faqs',
  //   roles: [ADMIN],
  //   icon: React.createElement(QuestionCircleOutlined),
  //   label: Strings.faq,
  //   path: FAQ_ROUTE
  // },
  {
    key: 'contact-us',
    icon: React.createElement(ContactsOutlined),
    label: Strings.contactUs,
    children: [
      {
        label: Strings.contactUsForms,
        key: 'contact-us-forms',
        roles: [ADMIN],
        path: CONTACT_US_FORMS_ROUTE
      },
      {
        label: Strings.contactUsFormSubmission,
        key: 'contact-add-form',
        roles: [AUTH],
        path: CONTACT_US_FORM_SUBMISSION_ROUTE
      },
      {
        label: Strings.contactUsMessages,
        key: 'contact-us-messages',
        roles: [ADMIN],
        path: CONTACT_US_MESSAGES_ROUTE
      },
      // {
      //   label: Strings.contactUsSpamMessages,
      //   key: 'contact-spam-message',
      //   roles: [ADMIN],
      //   path: CONTACT_US_SPAM_MESSAGES_ROUTE
      // }
    ]
  }
  // {
  //   key: 'entities',
  //   icon: React.createElement(ContactsOutlined),
  //   label: Strings.entities,
  //   children: [
  //     {
  //       label: Strings.entities,
  //       key: 'entities',
  //       roles: [ADMIN],
  //       path: ENTITIES_ROUTE
  //     },
  //     {
  //       label: Strings.entitySubmission,
  //       key: 'entity-submission',
  //       roles: [ADMIN],
  //       path: ENTITY_SUBMISSION_ROUTE
  //     },
  //     {
  //       label: Strings.entityMessages,
  //       key: 'entity-messages',
  //       roles: [ADMIN],
  //       path: ENTITY_MESSAGES_ROUTE
  //     }
  //   ]
  // },
  // {
  //   key: 'versions',
  //   roles: [ADMIN],
  //   icon: React.createElement(ContactsOutlined),
  //   label: Strings.versions,
  //   path: VERSION_ROUTE
  // },
  // {
  //   key: 'notify-users',
  //   roles: [ADMIN],
  //   icon: React.createElement(ContactsOutlined),
  //   label: Strings.notifyUsers,
  //   path: NOTIFY_USERS_ROUTE
  // }
]

const SideMenu = () => {
  const navigate = useNavigate()

  const { sideBarCollapsed } = useSelector((state: RootState) => state.sidebar)

  const { userProfile } = useSelector((state: RootState) => state.users)
  const [selectedKey, setSelectedKey] = useState('')
  const dispatch = useDispatch()
  const [menuItems, setMenuItems] = useState<ISideMenu[]>()

  useEffect(() => {
    callGetContactUsFormsApi()
  }, [])

  const callGetContactUsFormsApi = async () => {
    dispatch(setLoader(true))
    try {
      const contactResp: IContactUsFormResponse[] = await getContactUsForms()
      const AllMenuItems = [...items]
      if (contactResp.length > 0) {
        const newMenuItems = contactResp.map((val) => {
          return {
            key: val.id,
            roles: ['*'],
            icon: React.createElement(FormOutlined),
            label: val.name,
            path: CONTACT_US_MESSAGES_ROUTE,
            state: val.id
          }
        })
        AllMenuItems.push(...newMenuItems)
      }
      setMenuItems([...AllMenuItems].flat())
    } catch (err) {
      showToastMessage(MESSAGE_TYPE_ERROR, getErrorMessage(err as Error))
    } finally {
      dispatch(setLoader(false))
    }
  }

  const onClick: MenuProps['onClick'] = (e) => {
    if (menuItems) {
      const selectedMenuArray =
        menuItems &&
        menuItems.map((val) => (val.children ? val.children : val)).flat()
      const selectedMenuOption: ISideMenu | undefined = selectedMenuArray.find(
        (_item: ISideMenu) => e.key === _item.key
      )

      if (selectedMenuOption) {
        navigate(selectedMenuOption?.path || '', {
          state: selectedMenuOption.state
            ? { formId: selectedMenuOption.state }
            : ''
        })
        setSelectedKey(selectedMenuOption.key || '')
      }
    }
  }

  useEffect(() => {
    if (menuItems && menuItems.length > 0) {
      const selectedMenuArray =
        menuItems &&
        menuItems.map((val) => (val.children ? val.children : val)).flat()
      const key =
        selectedMenuArray.find(
          (_item: ISideMenu) => location?.pathname === _item.path
        )?.key || ''
      setSelectedKey(key)
    }
  }, [location.pathname, menuItems])

  const filterMenusByRoles = () => {
    const menus: ISideMenu[] = []
    if (userProfile) {
      menuItems &&
        menuItems.map((sideMenu) => {
          if (sideMenu.children) {
            let childMenus = []
            childMenus = sideMenu.children.filter((childMenu) => {
              return validateSideMenus(userProfile, childMenu.roles as string[])
            })
            sideMenu.children = childMenus
            if (childMenus.length > 0) {
              menus.push(sideMenu)
            }
          } else {
            if (validateSideMenus(userProfile, sideMenu.roles as string[])) {
              menus.push(sideMenu)
            }
          }
        })
    }
    console.log('menus',menus)
    return menus
  }

  return (
    <>
      <Sider
        theme='light'
        collapsible
        collapsed={sideBarCollapsed}>
        <Menu
          mode='inline'
          defaultSelectedKeys={['dashboard']}
          items={filterMenusByRoles()}
          onClick={onClick}
          selectedKeys={[selectedKey]}
        />
      </Sider>
    </>
  )
}

export default SideMenu
