export const CommonStrings = {
  en: {
    appName: 'Rainbow Health',
    success: 'Success',
    error: 'Error',
    users: 'Users',
    settings: 'Settings',
    notifications: 'Notifications',
    commonErrorMessage: 'Something went wrong.',
    forgotPassword: 'Forgot Password',
    faq: 'FAQS',
    resetPassword: 'Reset Password',
    changePassword: 'Change Password',
    pageNotFoundHeader: 'OOPS! PAGE NOT FOUND',
    pageNotFoundSubHeader: `WE ARE SORRY! BUT THE PAGE YOU REQUESTED \n WAS NOT FOUND`,
    contactUs: 'Forms',
    contactUsForms: 'Manage Forms',
    contactUsFormSubmission: 'Submit Form Data',
    contactUsMessages: 'Submitted Forms',
    contactUsSpamMessages: 'Contact Us Spam Messages',
    entities: 'Entities',
    entityMessages: 'Entity Messages',
    entitySubmission: 'Entity Submission',
    fileUploadText: 'Click or drag file to this area to upload',
    png: 'PNG',
    jpg: 'JPG',
    jpeg: 'JPEG',
    pdf: 'PDF',
    html: 'HTML',
    wordDoc: 'Word (.doc)',
    wordDocx: 'Word (.docx)',
    excel: 'Excel (xls)',
    excelX: 'Excel (xlsx)',
    download: 'Download',
    imageTypes: 'Image Types',
    fileTypes: 'File Types',
    phoneNoMinLengthError: 'must have 10 digits',
    emailInvalidError: 'Invalid Email!',
    spaceError: 'No spaces allowed',
    yes: 'yes',
    no: 'no',
    dropDownValueMinError: 'At least 1 choice is required',
    versions: 'Versions',
    notifyUsers: 'Notify-Users',
    deleteConfirmationText: 'Are you sure you want to delete this ?',
    fieldKeyConfirmationMsg:
      'You have changed the field key. You may lose the old data, are you sure you want to update?',
    choiceValueError: 'Choice value is already exist'
  }
}
